// Since we can't dynamically create button styles from the theme
// We keep this simple without any focus or active styles
// This button is used on the residential dashboard

$color: var(--theme-secondary);

.btn-accent {
  color: #fff;
  background-color: $color;
  border-color: $color;
}

.btn-accent:hover {
  color: #fff;
  background-color: $color;
  border-color: $color;
}

.btn-accent:focus,
.btn-accent.focus {
  box-shadow: none;
}

.btn-accent.disabled,
.btn-accent:disabled {
  color: #fff;
  background-color: $color;
  border-color: $color;
}

.btn-accent:not(:disabled):not(.disabled):active,
.btn-accent:not(:disabled):not(.disabled).active,
.show > .btn-accent.dropdown-toggle {
  color: #fff;
  background-color: $color;
  border-color: $color;
}

.btn-accent:not(:disabled):not(.disabled):active:focus,
.btn-accent:not(:disabled):not(.disabled).active:focus,
.show > .btn-accent.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-accent {
  color: $color;
  border-color: $color;
}

.btn-outline-accent:hover {
  color: #fff;
  background-color: $color;
  border-color: $color;
}

.btn-outline-accent:focus,
.btn-outline-accent.focus {
  box-shadow: none;
}

.btn-outline-accent.disabled,
.btn-outline-accent:disabled {
  color: $color;
  background-color: transparent;
}

.btn-outline-accent:not(:disabled):not(.disabled):active,
.btn-outline-accent:not(:disabled):not(.disabled).active,
.show > .btn-outline-accent.dropdown-toggle {
  color: #fff;
  background-color: $color;
  border-color: $color;
}

.btn-outline-accent:not(:disabled):not(.disabled):active:focus,
.btn-outline-accent:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-accent.dropdown-toggle:focus {
  box-shadow: none;
}

/* latin */
@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  src: local('Calibri'),
    url(https://fonts.gstatic.com/l/font?kit=J7afnpV-BGlaFfdAhLEY67FIEjg&skey=a1029226f80653a8&v=v10) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 700;
  src: local('Calibri Bold'), local('Calibri-Bold'),
    url(https://fonts.gstatic.com/l/font?kit=J7aanpV-BGlaFfdAjAo9_pxqHxIZrCE&skey=cd2dd6afe6bf0eb2&v=v10)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: local('PT Sans'), local('PTSans-Regular'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizaRExUiTo99u79D0-ExcOPIDUg-g.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: local('PT Sans'), local('PTSans-Regular'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizaRExUiTo99u79D0aExcOPIDUg-g.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: local('PT Sans'), local('PTSans-Regular'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizaRExUiTo99u79D0yExcOPIDUg-g.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: local('PT Sans'), local('PTSans-Regular'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizaRExUiTo99u79D0KExcOPIDU.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: local('PT Sans Bold'), local('PTSans-Bold'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizfRExUiTo99u79B_mh0OOtLR8a8zILig.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: local('PT Sans Bold'), local('PTSans-Bold'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizfRExUiTo99u79B_mh0OqtLR8a8zILig.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: local('PT Sans Bold'), local('PTSans-Bold'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizfRExUiTo99u79B_mh0OCtLR8a8zILig.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: local('PT Sans Bold'), local('PTSans-Bold'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizfRExUiTo99u79B_mh0O6tLR8a8zI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

#privacy-policy ol.lst-kix_list_7-0 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}
#privacy-policy ol.lst-kix_list_9-0.start {
  counter-reset: lst-ctn-kix_list_9-0 0;
}
#privacy-policy .lst-kix_list_6-1 > li {
  counter-increment: lst-ctn-kix_list_6-1;
}
#privacy-policy ol.lst-kix_list_3-1.start {
  counter-reset: lst-ctn-kix_list_3-1 0;
}
#privacy-policy ul.lst-kix_list_9-3 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_9-4 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_9-1 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_6-6.start {
  counter-reset: lst-ctn-kix_list_6-6 0;
}
#privacy-policy ul.lst-kix_list_9-2 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_9-7 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_7-4.start {
  counter-reset: lst-ctn-kix_list_7-4 0;
}
#privacy-policy ul.lst-kix_list_9-8 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_9-5 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_5-0 > li {
  counter-increment: lst-ctn-kix_list_5-0;
}
#privacy-policy .lst-kix_list_7-0 > li {
  counter-increment: lst-ctn-kix_list_7-0;
}
#privacy-policy .lst-kix_list_9-0 > li {
  counter-increment: lst-ctn-kix_list_9-0;
}
#privacy-policy ul.lst-kix_list_9-6 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}
#privacy-policy ol.lst-kix_list_7-5 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_1-3 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_7-6 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_1-4 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_7-7 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_1-1 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_7-8 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_1-2 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_7-1 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_1-7 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_7-2 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_1-8 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_7-3 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_1-5 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_7-4 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_1-6 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}
#privacy-policy ol.lst-kix_list_3-7.start {
  counter-reset: lst-ctn-kix_list_3-7 0;
}
#privacy-policy .lst-kix_list_3-2 > li {
  counter-increment: lst-ctn-kix_list_3-2;
}
#privacy-policy .lst-kix_list_7-2 > li {
  counter-increment: lst-ctn-kix_list_7-2;
}
#privacy-policy .lst-kix_list_5-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_5-0, lower-latin) ') ';
}
#privacy-policy ol.lst-kix_list_6-0 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_6-1 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_5-3 > li:before {
  content: '● ';
}
#privacy-policy .lst-kix_list_5-2 > li:before {
  content: '▪ ';
}
#privacy-policy .lst-kix_list_5-1 > li:before {
  content: 'o  ';
}
#privacy-policy .lst-kix_list_5-7 > li:before {
  content: 'o  ';
}
#privacy-policy ul.lst-kix_list_8-4 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_8-5 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_5-6 > li:before {
  content: '● ';
}
#privacy-policy .lst-kix_list_5-8 > li:before {
  content: '▪ ';
}
#privacy-policy ul.lst-kix_list_8-2 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_8-3 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_8-8 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_8-6 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_8-7 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_6-6 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_6-7 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_5-4 > li:before {
  content: 'o  ';
}
#privacy-policy ol.lst-kix_list_6-8 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_5-5 > li:before {
  content: '▪ ';
}
#privacy-policy ol.lst-kix_list_6-2 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_6-3 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_8-1 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_6-4 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_6-5 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
#privacy-policy .lst-kix_list_6-1 > li:before {
  content: '' counter(lst-ctn-kix_list_6-1, lower-latin) '. ';
}
#privacy-policy .lst-kix_list_6-3 > li:before {
  content: '' counter(lst-ctn-kix_list_6-3, decimal) '. ';
}
#privacy-policy .lst-kix_list_6-5 > li {
  counter-increment: lst-ctn-kix_list_6-5;
}
#privacy-policy .lst-kix_list_6-8 > li {
  counter-increment: lst-ctn-kix_list_6-8;
}
#privacy-policy .lst-kix_list_6-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_6-0, lower-latin) ') ';
}
#privacy-policy .lst-kix_list_6-4 > li:before {
  content: '' counter(lst-ctn-kix_list_6-4, lower-latin) '. ';
}
#privacy-policy .lst-kix_list_3-0 > li {
  counter-increment: lst-ctn-kix_list_3-0;
}
#privacy-policy ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}
#privacy-policy .lst-kix_list_3-6 > li {
  counter-increment: lst-ctn-kix_list_3-6;
}
#privacy-policy .lst-kix_list_6-2 > li:before {
  content: '' counter(lst-ctn-kix_list_6-2, lower-roman) '. ';
}
#privacy-policy .lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}
#privacy-policy .lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}
#privacy-policy ol.lst-kix_list_3-2.start {
  counter-reset: lst-ctn-kix_list_3-2 0;
}
#privacy-policy .lst-kix_list_6-8 > li:before {
  content: '' counter(lst-ctn-kix_list_6-8, lower-roman) '. ';
}
#privacy-policy .lst-kix_list_6-5 > li:before {
  content: '' counter(lst-ctn-kix_list_6-5, lower-roman) '. ';
}
#privacy-policy .lst-kix_list_6-7 > li:before {
  content: '' counter(lst-ctn-kix_list_6-7, lower-latin) '. ';
}
#privacy-policy ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}
#privacy-policy .lst-kix_list_6-6 > li:before {
  content: '' counter(lst-ctn-kix_list_6-6, decimal) '. ';
}
#privacy-policy .lst-kix_list_2-7 > li:before {
  content: '' counter(lst-ctn-kix_list_2-7, lower-latin) '. ';
}
#privacy-policy .lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}
#privacy-policy .lst-kix_list_7-4 > li:before {
  content: '' counter(lst-ctn-kix_list_7-4, lower-latin) '. ';
}
#privacy-policy .lst-kix_list_7-6 > li:before {
  content: '' counter(lst-ctn-kix_list_7-6, decimal) '. ';
}
#privacy-policy ol.lst-kix_list_1-0 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_2-5 > li:before {
  content: '' counter(lst-ctn-kix_list_2-5, lower-roman) '. ';
}
#privacy-policy ol.lst-kix_list_6-2.start {
  counter-reset: lst-ctn-kix_list_6-2 0;
}
#privacy-policy .lst-kix_list_7-2 > li:before {
  content: '' counter(lst-ctn-kix_list_7-2, lower-roman) '. ';
}
#privacy-policy .lst-kix_list_7-6 > li {
  counter-increment: lst-ctn-kix_list_7-6;
}
#privacy-policy .lst-kix_list_10-1 > li:before {
  content: 'o  ';
}
#privacy-policy ol.lst-kix_list_3-0.start {
  counter-reset: lst-ctn-kix_list_3-0 0;
}
#privacy-policy .lst-kix_list_7-7 > li {
  counter-increment: lst-ctn-kix_list_7-7;
}
#privacy-policy .lst-kix_list_7-8 > li:before {
  content: '' counter(lst-ctn-kix_list_7-8, lower-roman) '. ';
}
#privacy-policy ol.lst-kix_list_9-0 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_10-7 > li:before {
  content: 'o  ';
}
#privacy-policy .lst-kix_list_7-8 > li {
  counter-increment: lst-ctn-kix_list_7-8;
}
#privacy-policy ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}
#privacy-policy .lst-kix_list_10-5 > li:before {
  content: '▪ ';
}
#privacy-policy .lst-kix_list_10-3 > li:before {
  content: '● ';
}
#privacy-policy .lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}
#privacy-policy .lst-kix_list_4-1 > li:before {
  content: 'o  ';
}
#privacy-policy ol.lst-kix_list_7-3.start {
  counter-reset: lst-ctn-kix_list_7-3 0;
}
#privacy-policy .lst-kix_list_9-2 > li:before {
  content: '▪ ';
}
#privacy-policy .lst-kix_list_4-3 > li:before {
  content: '● ';
}
#privacy-policy .lst-kix_list_4-5 > li:before {
  content: '▪ ';
}
#privacy-policy .lst-kix_list_3-5 > li {
  counter-increment: lst-ctn-kix_list_3-5;
}
#privacy-policy .lst-kix_list_9-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_9-0, lower-latin) ') ';
}
#privacy-policy .lst-kix_list_3-4 > li {
  counter-increment: lst-ctn-kix_list_3-4;
}
#privacy-policy .lst-kix_list_6-4 > li {
  counter-increment: lst-ctn-kix_list_6-4;
}
#privacy-policy .lst-kix_list_9-6 > li:before {
  content: '● ';
}
#privacy-policy .lst-kix_list_9-4 > li:before {
  content: 'o  ';
}
#privacy-policy .lst-kix_list_6-3 > li {
  counter-increment: lst-ctn-kix_list_6-3;
}
#privacy-policy ol.lst-kix_list_10-0 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}
#privacy-policy ol.lst-kix_list_7-6.start {
  counter-reset: lst-ctn-kix_list_7-6 0;
}
#privacy-policy ol.lst-kix_list_6-1.start {
  counter-reset: lst-ctn-kix_list_6-1 0;
}
#privacy-policy ol.lst-kix_list_8-0 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_9-8 > li:before {
  content: '▪ ';
}
#privacy-policy .lst-kix_list_1-1 > li:before {
  content: 'o  ';
}
#privacy-policy .lst-kix_list_1-3 > li:before {
  content: '● ';
}
#privacy-policy ul.lst-kix_list_10-8 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_10-7 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_1-7 > li:before {
  content: 'o  ';
}
#privacy-policy ul.lst-kix_list_10-6 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}
#privacy-policy ul.lst-kix_list_10-5 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_10-4 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_10-3 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_1-5 > li:before {
  content: '▪ ';
}
#privacy-policy ul.lst-kix_list_10-2 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_10-1 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_7-5.start {
  counter-reset: lst-ctn-kix_list_7-5 0;
}
#privacy-policy .lst-kix_list_2-1 > li:before {
  content: '' counter(lst-ctn-kix_list_2-1, lower-latin) '. ';
}
#privacy-policy ol.lst-kix_list_6-0.start {
  counter-reset: lst-ctn-kix_list_6-0 0;
}
#privacy-policy .lst-kix_list_2-3 > li:before {
  content: '' counter(lst-ctn-kix_list_2-3, decimal) '. ';
}
#privacy-policy ol.lst-kix_list_3-1 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_3-2 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_3-1 > li {
  counter-increment: lst-ctn-kix_list_3-1;
}
#privacy-policy ol.lst-kix_list_3-3 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_3-4.start {
  counter-reset: lst-ctn-kix_list_3-4 0;
}
#privacy-policy ol.lst-kix_list_3-4 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_3-0 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_7-1 > li {
  counter-increment: lst-ctn-kix_list_7-1;
}
#privacy-policy ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}
#privacy-policy .lst-kix_list_3-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_3-0, lower-latin) ') ';
}
#privacy-policy ol.lst-kix_list_7-7.start {
  counter-reset: lst-ctn-kix_list_7-7 0;
}
#privacy-policy ul.lst-kix_list_5-7 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_5-8 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_3-1 > li:before {
  content: '' counter(lst-ctn-kix_list_3-1, lower-latin) '. ';
}
#privacy-policy .lst-kix_list_3-2 > li:before {
  content: '' counter(lst-ctn-kix_list_3-2, lower-roman) '. ';
}
#privacy-policy ul.lst-kix_list_5-5 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_5-6 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_8-1 > li:before {
  content: 'o  ';
}
#privacy-policy .lst-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0;
}
#privacy-policy .lst-kix_list_8-2 > li:before {
  content: '▪ ';
}
#privacy-policy .lst-kix_list_6-0 > li {
  counter-increment: lst-ctn-kix_list_6-0;
}
#privacy-policy .lst-kix_list_8-0 > li {
  counter-increment: lst-ctn-kix_list_8-0;
}
#privacy-policy .lst-kix_list_3-5 > li:before {
  content: '' counter(lst-ctn-kix_list_3-5, lower-roman) '. ';
}
#privacy-policy .lst-kix_list_10-0 > li {
  counter-increment: lst-ctn-kix_list_10-0;
}
#privacy-policy .lst-kix_list_3-4 > li:before {
  content: '' counter(lst-ctn-kix_list_3-4, lower-latin) '. ';
}
#privacy-policy ul.lst-kix_list_5-3 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_3-3 > li:before {
  content: '' counter(lst-ctn-kix_list_3-3, decimal) '. ';
}
#privacy-policy ol.lst-kix_list_3-5 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_5-4 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_3-6 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_5-1 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_8-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_8-0, lower-latin) ') ';
}
#privacy-policy ol.lst-kix_list_3-7 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_5-2 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_3-8 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_8-7 > li:before {
  content: 'o  ';
}
#privacy-policy .lst-kix_list_3-8 > li:before {
  content: '' counter(lst-ctn-kix_list_3-8, lower-roman) '. ';
}
#privacy-policy .lst-kix_list_8-5 > li:before {
  content: '▪ ';
}
#privacy-policy .lst-kix_list_8-6 > li:before {
  content: '● ';
}
#privacy-policy .lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}
#privacy-policy .lst-kix_list_8-3 > li:before {
  content: '● ';
}
#privacy-policy .lst-kix_list_3-6 > li:before {
  content: '' counter(lst-ctn-kix_list_3-6, decimal) '. ';
}
#privacy-policy .lst-kix_list_3-7 > li:before {
  content: '' counter(lst-ctn-kix_list_3-7, lower-latin) '. ';
}
#privacy-policy .lst-kix_list_8-4 > li:before {
  content: 'o  ';
}
#privacy-policy ol.lst-kix_list_5-0.start {
  counter-reset: lst-ctn-kix_list_5-0 0;
}
#privacy-policy .lst-kix_list_8-8 > li:before {
  content: '▪ ';
}
#privacy-policy ol.lst-kix_list_2-2 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_2-3 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_2-4 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_7-2.start {
  counter-reset: lst-ctn-kix_list_7-2 0;
}
#privacy-policy ol.lst-kix_list_2-5 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_2-0 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_2-1 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_4-8 > li:before {
  content: '▪ ';
}
#privacy-policy ol.lst-kix_list_6-4.start {
  counter-reset: lst-ctn-kix_list_6-4 0;
}
#privacy-policy .lst-kix_list_4-7 > li:before {
  content: 'o  ';
}
#privacy-policy ul.lst-kix_list_4-8 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_4-6 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_4-7 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_7-3 > li {
  counter-increment: lst-ctn-kix_list_7-3;
}
#privacy-policy ul.lst-kix_list_4-1 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_3-3.start {
  counter-reset: lst-ctn-kix_list_3-3 0;
}
#privacy-policy ul.lst-kix_list_4-4 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_2-6 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_4-5 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_2-7 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_4-2 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_2-8 {
  list-style-type: none;
}
#privacy-policy ul.lst-kix_list_4-3 {
  list-style-type: none;
}
#privacy-policy ol.lst-kix_list_7-8.start {
  counter-reset: lst-ctn-kix_list_7-8 0;
}
#privacy-policy ol.lst-kix_list_7-1.start {
  counter-reset: lst-ctn-kix_list_7-1 0;
}
#privacy-policy .lst-kix_list_3-3 > li {
  counter-increment: lst-ctn-kix_list_3-3;
}
#privacy-policy ol.lst-kix_list_6-3.start {
  counter-reset: lst-ctn-kix_list_6-3 0;
}
#privacy-policy ol.lst-kix_list_8-0.start {
  counter-reset: lst-ctn-kix_list_8-0 0;
}
#privacy-policy .lst-kix_list_7-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_7-0, lower-latin) ') ';
}
#privacy-policy .lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}
#privacy-policy .lst-kix_list_6-2 > li {
  counter-increment: lst-ctn-kix_list_6-2;
}
#privacy-policy ol.lst-kix_list_5-0 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_2-6 > li:before {
  content: '' counter(lst-ctn-kix_list_2-6, decimal) '. ';
}
#privacy-policy .lst-kix_list_3-7 > li {
  counter-increment: lst-ctn-kix_list_3-7;
}
#privacy-policy .lst-kix_list_2-4 > li:before {
  content: '' counter(lst-ctn-kix_list_2-4, lower-latin) '. ';
}
#privacy-policy .lst-kix_list_2-8 > li:before {
  content: '' counter(lst-ctn-kix_list_2-8, lower-roman) '. ';
}
#privacy-policy .lst-kix_list_7-1 > li:before {
  content: '' counter(lst-ctn-kix_list_7-1, lower-latin) '. ';
}
#privacy-policy .lst-kix_list_7-5 > li:before {
  content: '' counter(lst-ctn-kix_list_7-5, lower-roman) '. ';
}
#privacy-policy .lst-kix_list_6-6 > li {
  counter-increment: lst-ctn-kix_list_6-6;
}
#privacy-policy .lst-kix_list_7-3 > li:before {
  content: '' counter(lst-ctn-kix_list_7-3, decimal) '. ';
}
#privacy-policy .lst-kix_list_10-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_10-0, lower-latin) ') ';
}
#privacy-policy .lst-kix_list_6-7 > li {
  counter-increment: lst-ctn-kix_list_6-7;
}
#privacy-policy ol.lst-kix_list_3-8.start {
  counter-reset: lst-ctn-kix_list_3-8 0;
}
#privacy-policy .lst-kix_list_7-7 > li:before {
  content: '' counter(lst-ctn-kix_list_7-7, lower-latin) '. ';
}
#privacy-policy ol.lst-kix_list_10-0.start {
  counter-reset: lst-ctn-kix_list_10-0 0;
}
#privacy-policy .lst-kix_list_7-5 > li {
  counter-increment: lst-ctn-kix_list_7-5;
}
#privacy-policy .lst-kix_list_10-4 > li:before {
  content: 'o  ';
}
#privacy-policy .lst-kix_list_10-8 > li:before {
  content: '▪ ';
}
#privacy-policy .lst-kix_list_4-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_4-0, lower-latin) ') ';
}
#privacy-policy .lst-kix_list_3-8 > li {
  counter-increment: lst-ctn-kix_list_3-8;
}
#privacy-policy ol.lst-kix_list_6-8.start {
  counter-reset: lst-ctn-kix_list_6-8 0;
}
#privacy-policy .lst-kix_list_10-2 > li:before {
  content: '▪ ';
}
#privacy-policy .lst-kix_list_4-4 > li:before {
  content: 'o  ';
}
#privacy-policy ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}
#privacy-policy ol.lst-kix_list_6-5.start {
  counter-reset: lst-ctn-kix_list_6-5 0;
}
#privacy-policy .lst-kix_list_4-2 > li:before {
  content: '▪ ';
}
#privacy-policy .lst-kix_list_4-6 > li:before {
  content: '● ';
}
#privacy-policy .lst-kix_list_9-3 > li:before {
  content: '● ';
}
#privacy-policy ol.lst-kix_list_7-0.start {
  counter-reset: lst-ctn-kix_list_7-0 0;
}
#privacy-policy .lst-kix_list_10-6 > li:before {
  content: '● ';
}
#privacy-policy .lst-kix_list_9-1 > li:before {
  content: 'o  ';
}
#privacy-policy ol.lst-kix_list_4-0 {
  list-style-type: none;
}
#privacy-policy .lst-kix_list_9-7 > li:before {
  content: 'o  ';
}
#privacy-policy .lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}
#privacy-policy ol.lst-kix_list_6-7.start {
  counter-reset: lst-ctn-kix_list_6-7 0;
}
#privacy-policy ol.lst-kix_list_3-6.start {
  counter-reset: lst-ctn-kix_list_3-6 0;
}
#privacy-policy .lst-kix_list_9-5 > li:before {
  content: '▪ ';
}
#privacy-policy .lst-kix_list_7-4 > li {
  counter-increment: lst-ctn-kix_list_7-4;
}
#privacy-policy .lst-kix_list_1-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-0, lower-latin) ') ';
}
#privacy-policy .lst-kix_list_1-2 > li:before {
  content: '▪ ';
}
#privacy-policy ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}
#privacy-policy .lst-kix_list_1-4 > li:before {
  content: 'o  ';
}
#privacy-policy ol.lst-kix_list_3-5.start {
  counter-reset: lst-ctn-kix_list_3-5 0;
}
#privacy-policy .lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
#privacy-policy .lst-kix_list_1-6 > li:before {
  content: '● ';
}
#privacy-policy .lst-kix_list_2-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_2-0, lower-latin) ') ';
}
#privacy-policy ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}
#privacy-policy .lst-kix_list_1-8 > li:before {
  content: '▪ ';
}
#privacy-policy .lst-kix_list_2-2 > li:before {
  content: '' counter(lst-ctn-kix_list_2-2, lower-roman) '. ';
}
#privacy-policy ol {
  margin: 0;
  padding: 0;
}
#privacy-policy table td,
#privacy-policy table th {
  padding: 0;
}
#privacy-policy .c4 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 6pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy .c17 {
  margin-left: 36pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy .c13 {
  margin-left: 21.6pt;
  padding-top: 0pt;
  text-indent: -3.6pt;
  padding-bottom: 6pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy .c23 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 8pt;
  line-height: 1.0791666667;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy .c6 {
  margin-left: 45pt;
  padding-top: 12pt;
  text-indent: -45pt;
  padding-bottom: 12pt;
  line-height: 1.0791666667;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy .c15 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.0791666667;
  orphans: 2;
  widows: 2;
  text-align: center;
}
#privacy-policy .c18 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy .c22 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
#privacy-policy .c2 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy .c20 {
  padding-top: 6pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy .c10 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-style: normal;
}
#privacy-policy .c14 {
  color: #000000;
  font-weight: 400;
  font-size: 11pt;
  font-family: 'Calibri';
}
#privacy-policy .c0 {
  font-size: 11.5pt;
  font-family: 'PT Sans';
  color: #000000;
  font-weight: 400;
}
#privacy-policy .c25 {
  font-size: 11.5pt;
  font-family: 'PT Sans';
  color: #0432ff;
  font-weight: 400;
}
#privacy-policy .c1 {
  font-size: 11.5pt;
  font-family: 'PT Sans';
  color: #000000;
  font-weight: 700;
}
#privacy-policy .c5 {
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  font-family: 'PT Sans';
}
#privacy-policy .c26 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
#privacy-policy .c21 {
  font-weight: 400;
  font-size: 10pt;
  font-family: 'PT Sans';
}
#privacy-policy .c19 {
  font-weight: 700;
  font-size: 16pt;
  font-family: 'PT Sans';
}
#privacy-policy .c11 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
#privacy-policy .c24 {
  font-weight: 400;
  font-size: 16pt;
  font-family: 'Calibri';
}
#privacy-policy .c3 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
#privacy-policy .c9 {
  padding: 0;
  margin: 0;
}
#privacy-policy .c12 {
  margin-left: 36pt;
  text-indent: -36pt;
}
#privacy-policy .c8 {
  margin-left: 18pt;
  text-indent: -18pt;
}
#privacy-policy .c16 {
  color: #000000;
}
#privacy-policy .c7 {
  height: 11pt;
}
#privacy-policy .title {
  padding-top: 0pt;
  color: #000000;
  font-size: 12pt;
  padding-bottom: 0pt;
  font-family: 'Times New Roman';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy .subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: 'Georgia';
  line-height: 1.0791666667;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy li {
  color: #000000;
  font-size: 11pt;
  font-family: 'Calibri';
}
#privacy-policy p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: 'Calibri';
}
#privacy-policy h1 {
  padding-top: 12pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 3pt;
  font-family: 'Calibri';
  line-height: 1.0791666667;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: 'Calibri';
  line-height: 1.0791666667;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Calibri';
  line-height: 1.0791666667;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1.0791666667;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1.0791666667;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
#privacy-policy h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1.0791666667;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

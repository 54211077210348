.badge {
  color: #fff;
  text-transform: uppercase;
  padding: 0.6em;
}

.defaultUserBadge {
  margin-top: 1px;
  margin-left: 0.5rem;
  font-size: 0.7rem;
  padding: 0.25rem 0.5rem;
  background: #0072b7;
  color: white;
  border-radius: 25px;
}

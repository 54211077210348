.card {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  //   border-top: 2px solid var(--theme-primary);
}

.card-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid #ebeff2;
}

.card-title {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  color: #333333;
}

.card-content {
  padding: 1.25rem;
}

.card-body {
  color: #333333;
}

.table {
  margin-bottom: 0;
}

.table th {
  color: #1a202c;
}

.table th,
.table td {
  padding: 15px 20px;
}

.table-sm th,
.table-sm td {
  padding: 10px 5px;
}

.table-sm td .row {
  margin-left: -5px;
  margin-right: -5px;
}

.table-sm td .row .col {
  padding-left: 5px;
  padding-right: 5px;
}

.table tr:first-child th,
.table tr:first-child td {
  border-top: 0;
}

.table tfoot tr {
  border-top: 2px solid #dee2e6;
}

.table-hover tbody tr:hover {
  cursor: pointer;
}

.table.table-flex tr {
  display: flex;
}

.table.table-flex thead {
  display: block;
}

.table.table-flex tbody {
  display: block;
}

.table.table-flex tbody td {
  display: flex;
  align-items: center;
  height: 50px;
}

.table.table-scroll tbody {
  max-height: 280px;
  overflow-y: auto;
}

.table.table-fixed {
  table-layout: fixed;
}

.table td.cell-shaded {
  background-color: #e7e7e7;
  font-style: italic;
}

.table.table-flex th,
.table.table-flex td {
  flex: 1;
}

@media (max-width: 1200px) {
  .table .table-progress-bar {
    display: none;
  }
}

$body-bg: #1a202c;
$body-color: #4a5568;
// $green: #82d132;
$blue: #0072b7;
$cyan: #27afd0;
$red: #cf0020;
$orange: #f4a431;

$font-family-sans-serif: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$warning: $orange;

$card-spacer-x: 0;
$card-border-width: 0;

$table-hover-bg: rgba(0, 0, 0, 0.025);

$zindex-tooltip: 1020;

@import 'variables';
@import '~bootstrap/scss/bootstrap.scss';

@import 'buttons';
@import 'accent-btn';
@import 'card';
@import 'forms';
@import 'tables';
@import 'badge';
@import 'toast';

html,
body,
#root {
  width: 100%;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
}

td.text-truncate {
  max-width: 1px;
}

.row.row-bordered {
  border-top: 1px solid #dee2e6;
  padding: 15px 5px;
}

.row.row-bordered:first-child {
  border-top: 0;
}

.form-control-plaintext {
  padding: $input-padding-y $input-padding-x;
  border-width: $input-border-width;
}

.form-select {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4a5568;
  background-color: hsl(210deg, 14%, 98%);
  background-clip: padding-box;
  border: 1px solid #cad0d6;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s;
}

.input-group-text-prepend {
  margin-right: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
}

.input-group-text-append {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
}

select:focus,
select:focus-visible {
  color: #4a5568;
  background-color: #fff;
  border-color: #38b4ff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 114, 183, 0.25);
}

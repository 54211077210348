/* cyrillic-ext */
@font-face {
  font-family: 'PT Sans';
  font-weight: 400;
  font-style: normal;

  src: local('PT Sans'), local('PTSans-Regular'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizaRExUiTo99u79D0-ExcOPIDUg-g.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'PT Sans';
  font-weight: 400;
  font-style: normal;

  src: local('PT Sans'), local('PTSans-Regular'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizaRExUiTo99u79D0aExcOPIDUg-g.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'PT Sans';
  font-weight: 400;
  font-style: normal;

  src: local('PT Sans'), local('PTSans-Regular'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizaRExUiTo99u79D0yExcOPIDUg-g.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'PT Sans';
  font-weight: 400;
  font-style: normal;

  src: local('PT Sans'), local('PTSans-Regular'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizaRExUiTo99u79D0KExcOPIDU.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'PT Sans';
  font-weight: 700;
  font-style: normal;

  src: local('PT Sans Bold'), local('PTSans-Bold'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizfRExUiTo99u79B_mh0OOtLR8a8zILig.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'PT Sans';
  font-weight: 700;
  font-style: normal;

  src: local('PT Sans Bold'), local('PTSans-Bold'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizfRExUiTo99u79B_mh0OqtLR8a8zILig.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'PT Sans';
  font-weight: 700;
  font-style: normal;

  src: local('PT Sans Bold'), local('PTSans-Bold'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizfRExUiTo99u79B_mh0OCtLR8a8zILig.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'PT Sans';
  font-weight: 700;
  font-style: normal;

  src: local('PT Sans Bold'), local('PTSans-Bold'),
    url(https://fonts.gstatic.com/s/ptsans/v9/jizfRExUiTo99u79B_mh0O6tLR8a8zI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

#terms ol.lst-kix_list_3-1 {
  list-style-type: none;
}

#terms ol.lst-kix_list_3-2 {
  list-style-type: none;
}

#terms .lst-kix_list_3-1 > li {
  counter-increment: lst-ctn-kix_list_3-1;
}

#terms ol.lst-kix_list_3-3 {
  list-style-type: none;
}

#terms ol.lst-kix_list_3-4.start {
  counter-reset: lst-ctn-kix_list_3-4 0;
}

#terms ol.lst-kix_list_3-4 {
  list-style-type: none;
}

#terms .lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}

#terms ol.lst-kix_list_3-0 {
  list-style-type: none;
}

#terms .lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}

#terms ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}

#terms .lst-kix_list_3-0 > li:before {
  content: '' counter(lst-ctn-kix_list_3-0, decimal) '. ';
}

#terms ol.lst-kix_list_3-1.start {
  counter-reset: lst-ctn-kix_list_3-1 0;
}

#terms .lst-kix_list_3-1 > li:before {
  content: '' counter(lst-ctn-kix_list_3-1, lower-latin) '. ';
}

#terms .lst-kix_list_3-2 > li:before {
  content: '' counter(lst-ctn-kix_list_3-2, lower-roman) '. ';
}

#terms ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}

#terms ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}

#terms .lst-kix_list_3-5 > li:before {
  content: '' counter(lst-ctn-kix_list_3-5, lower-roman) '. ';
}

#terms .lst-kix_list_3-4 > li:before {
  content: '' counter(lst-ctn-kix_list_3-4, lower-latin) '. ';
}

#terms ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}

#terms .lst-kix_list_3-3 > li:before {
  content: '' counter(lst-ctn-kix_list_3-3, decimal) '. ';
}

#terms ol.lst-kix_list_3-5 {
  list-style-type: none;
}

#terms ol.lst-kix_list_3-6 {
  list-style-type: none;
}

#terms ol.lst-kix_list_3-7 {
  list-style-type: none;
}

#terms ol.lst-kix_list_3-8 {
  list-style-type: none;
}

#terms .lst-kix_list_3-8 > li:before {
  content: '' counter(lst-ctn-kix_list_3-8, lower-roman) '. ';
}

#terms .lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}

#terms .lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}

#terms .lst-kix_list_3-6 > li:before {
  content: '' counter(lst-ctn-kix_list_3-6, decimal) '. ';
}

#terms .lst-kix_list_3-7 > li:before {
  content: '' counter(lst-ctn-kix_list_3-7, lower-latin) '. ';
}

#terms .lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}

#terms ol.lst-kix_list_3-7.start {
  counter-reset: lst-ctn-kix_list_3-7 0;
}

#terms .lst-kix_list_3-2 > li {
  counter-increment: lst-ctn-kix_list_3-2;
}

#terms ol.lst-kix_list_2-2 {
  list-style-type: none;
}

#terms ol.lst-kix_list_2-3 {
  list-style-type: none;
}

#terms ol.lst-kix_list_2-4 {
  list-style-type: none;
}

#terms ol.lst-kix_list_2-5 {
  list-style-type: none;
}

#terms .lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}

#terms ol.lst-kix_list_2-0 {
  list-style-type: none;
}

#terms ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}

#terms ol.lst-kix_list_2-1 {
  list-style-type: none;
}

#terms ol.lst-kix_list_3-3.start {
  counter-reset: lst-ctn-kix_list_3-3 0;
}

#terms ol.lst-kix_list_2-6 {
  list-style-type: none;
}

#terms ol.lst-kix_list_2-7 {
  list-style-type: none;
}

#terms ol.lst-kix_list_2-8 {
  list-style-type: none;
}

#terms ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}

#terms .lst-kix_list_3-0 > li {
  counter-increment: lst-ctn-kix_list_3-0;
}

#terms .lst-kix_list_3-3 > li {
  counter-increment: lst-ctn-kix_list_3-3;
}

#terms .lst-kix_list_3-6 > li {
  counter-increment: lst-ctn-kix_list_3-6;
}

#terms .lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}

#terms .lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}

#terms ol.lst-kix_list_3-2.start {
  counter-reset: lst-ctn-kix_list_3-2 0;
}

#terms .lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}

#terms ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}

#terms ol.lst-kix_list_1-3 {
  list-style-type: none;
}

#terms ol.lst-kix_list_1-4 {
  list-style-type: none;
}

#terms .lst-kix_list_2-6 > li:before {
  content: '' counter(lst-ctn-kix_list_2-6, decimal) '. ';
}

#terms .lst-kix_list_2-7 > li:before {
  content: '' counter(lst-ctn-kix_list_2-7, lower-latin) '. ';
}

#terms .lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}

#terms .lst-kix_list_3-7 > li {
  counter-increment: lst-ctn-kix_list_3-7;
}

#terms ol.lst-kix_list_1-5 {
  list-style-type: none;
}

#terms ol.lst-kix_list_1-6 {
  list-style-type: none;
}

#terms ol.lst-kix_list_1-0 {
  list-style-type: none;
}

#terms .lst-kix_list_2-4 > li:before {
  content: '' counter(lst-ctn-kix_list_2-4, lower-latin) '. ';
}

#terms .lst-kix_list_2-5 > li:before {
  content: '' counter(lst-ctn-kix_list_2-5, lower-roman) '. ';
}

#terms .lst-kix_list_2-8 > li:before {
  content: '' counter(lst-ctn-kix_list_2-8, lower-roman) '. ';
}

#terms ol.lst-kix_list_1-1 {
  list-style-type: none;
}

#terms ol.lst-kix_list_1-2 {
  list-style-type: none;
}

#terms ol.lst-kix_list_3-0.start {
  counter-reset: lst-ctn-kix_list_3-0 0;
}

#terms ol.lst-kix_list_1-7 {
  list-style-type: none;
}

#terms .lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}

#terms ol.lst-kix_list_1-8 {
  list-style-type: none;
}

#terms ol.lst-kix_list_3-8.start {
  counter-reset: lst-ctn-kix_list_3-8 0;
}

#terms ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}

#terms .lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}

#terms .lst-kix_list_3-8 > li {
  counter-increment: lst-ctn-kix_list_3-8;
}

#terms ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}

#terms ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}

#terms .lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}

#terms .lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}

#terms ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}

#terms .lst-kix_list_3-5 > li {
  counter-increment: lst-ctn-kix_list_3-5;
}

#terms ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}

#terms .lst-kix_list_3-4 > li {
  counter-increment: lst-ctn-kix_list_3-4;
}

#terms .lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}

#terms ol.lst-kix_list_3-6.start {
  counter-reset: lst-ctn-kix_list_3-6 0;
}

#terms ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}

#terms ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}

#terms ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}

#terms .lst-kix_list_1-0 > li:before {
  content: 'Article ' counter(lst-ctn-kix_list_1-0, upper-roman) ' ';
}

#terms .lst-kix_list_1-1 > li:before {
  content: 'Section ' counter(lst-ctn-kix_list_1-0, upper-roman) '.' counter(lst-ctn-kix_list_1-1, decimal-leading-zero)
    ' ';
}

#terms .lst-kix_list_1-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-2, lower-latin) ') ';
}

#terms ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}

#terms .lst-kix_list_1-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-3, lower-roman) ') ';
}

#terms .lst-kix_list_1-4 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-4, upper-latin) ') ';
}

#terms ol.lst-kix_list_3-5.start {
  counter-reset: lst-ctn-kix_list_3-5 0;
}

#terms .lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}

#terms .lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}

#terms .lst-kix_list_1-7 > li:before {
  content: '' counter(lst-ctn-kix_list_1-7, decimal) '. ';
}

#terms ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}

#terms .lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}

#terms .lst-kix_list_1-5 > li:before {
  content: '' counter(lst-ctn-kix_list_1-5, decimal) '. ';
}

#terms .lst-kix_list_1-6 > li:before {
  content: '' counter(lst-ctn-kix_list_1-6, decimal) '. ';
}

#terms .lst-kix_list_2-0 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '. ';
}

#terms .lst-kix_list_2-1 > li:before {
  content: '' counter(lst-ctn-kix_list_2-1, lower-latin) '. ';
}

#terms ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}

#terms .lst-kix_list_1-8 > li:before {
  content: '' counter(lst-ctn-kix_list_1-8, decimal) '. ';
}

#terms .lst-kix_list_2-2 > li:before {
  content: '' counter(lst-ctn-kix_list_2-2, lower-roman) '. ';
}

#terms .lst-kix_list_2-3 > li:before {
  content: '' counter(lst-ctn-kix_list_2-3, decimal) '. ';
}

#terms ol {
  margin: 0;
  padding: 0;
}

#terms table td,
#terms table th {
  padding: 0;
}

#terms .c0 {
  vertical-align: baseline;
  text-decoration: underline;
  color: #000;
  font-family: 'PT Sans';
  font-size: 10.5pt;
  font-weight: 700;
  font-style: normal;

  -webkit-text-decoration-skip: none;
  text-decoration-skip-ink: none;
}

#terms .c1 {
  margin-left: 18pt;
  padding-top: 12pt;
  padding-bottom: 6pt;
  text-align: left;
  text-indent: -18pt;
  line-height: 1;

  orphans: 2;
  widows: 2;
}

#terms .c17 {
  margin-left: 72pt;
  padding-top: 6pt;
  padding-bottom: 6pt;
  text-align: left;
  text-indent: -36pt;
  line-height: 1;

  orphans: 2;
  widows: 2;
}

#terms .c3 {
  vertical-align: baseline;
  text-decoration: none;
  color: #000;
  font-family: 'PT Sans';
  font-size: 10.5pt;
  font-weight: 400;
  font-style: normal;
}

#terms .c24 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  text-align: center;
  line-height: 1;

  orphans: 2;
  widows: 2;
}

#terms .c22 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  text-align: justify;
  line-height: 1;

  orphans: 2;
  widows: 2;
}

#terms .c2 {
  padding-top: 12pt;
  padding-bottom: 6pt;
  text-align: left;
  line-height: 1;

  orphans: 2;
  widows: 2;
}

#terms .c15 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  text-align: center;
  line-height: 1;

  orphans: 2;
  widows: 2;
}

#terms .c4 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  text-align: left;
  line-height: 1;

  orphans: 2;
  widows: 2;
}

#terms .c18 {
  text-decoration: underline;
  color: #00f;

  -webkit-text-decoration-skip: none;
  text-decoration-skip-ink: none;
}

#terms .c5 {
  color: #000;
  font-family: 'PT Sans';
  font-size: 10.5pt;
  font-weight: 400;
}

#terms .c20 {
  font-family: 'Times New Roman';
  font-size: 6pt;
  font-weight: 400;
}

#terms .c19 {
  text-decoration: underline;

  -webkit-text-decoration-skip: none;
  text-decoration-skip-ink: none;
}

#terms .c26 {
  font-family: 'PT Sans';
  font-size: 10pt;
  font-weight: 400;
}

#terms .c12 {
  vertical-align: baseline;
  text-decoration: none;
  font-style: normal;
}

#terms .c16 {
  max-width: 432.4pt;
  padding: 72pt 89.8pt 72pt 89.8pt;
  background-color: #fff;
}

#terms .c21 {
  font-family: 'PT Sans';
  font-size: 14pt;
  font-weight: 400;
}

#terms .c23 {
  font-family: 'Times New Roman';
  font-size: 12pt;
  font-weight: 400;
}

#terms .c10 {
  font-family: 'PT Sans';
  font-size: 10.5pt;
  font-weight: 400;
}

#terms .c14 {
  text-decoration: inherit;
  color: inherit;
}

#terms .c7 {
  color: #000;
  font-size: 10.5pt;
}

#terms .c6 {
  font-family: 'PT Sans';
  font-weight: 700;
}

#terms .c8 {
  font-style: italic;
}

#terms .c11 {
  color: #000;
}

#terms .c13 {
  color: #0432ff;
}

#terms .c25 {
  font-size: 16pt;
}

#terms .c9 {
  height: 12pt;
}

#terms .title {
  padding-top: 24pt;
  padding-bottom: 6pt;
  text-align: left;
  color: #000;
  font-family: 'Times New Roman';
  font-size: 36pt;
  font-weight: 700;
  line-height: 1;

  orphans: 2;
  page-break-after: avoid;
  widows: 2;
}

#terms .subtitle {
  padding-top: 18pt;
  padding-bottom: 4pt;
  text-align: left;
  color: #666;
  font-family: 'Georgia';
  font-size: 24pt;
  font-style: italic;
  line-height: 1;

  orphans: 2;
  page-break-after: avoid;
  widows: 2;
}

#terms li {
  color: #000;
  font-family: 'Times New Roman';
  font-size: 12pt;
}

#terms p {
  margin: 0;
  color: #000;
  font-family: 'Times New Roman';
  font-size: 12pt;
}

#terms h1 {
  padding-top: 16pt;
  padding-bottom: 0pt;
  text-align: center;
  color: #000;
  font-family: 'Times New Roman';
  font-size: 12pt;
  font-weight: 700;
  line-height: 1;

  orphans: 2;
  page-break-after: avoid;
  widows: 2;
}

#terms h2 {
  padding-top: 14pt;
  padding-bottom: 6pt;
  text-align: left;
  color: #000;
  font-family: 'Times New Roman';
  font-size: 12pt;
  line-height: 1;

  orphans: 2;
  widows: 2;
}

#terms h3 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  text-align: left;
  color: #000;
  font-family: 'Times New Roman';
  font-size: 12pt;
  line-height: 1;

  orphans: 2;
  widows: 2;
}

#terms h4 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  text-align: left;
  color: #000;
  font-family: 'Times New Roman';
  font-size: 12pt;
  line-height: 1;

  orphans: 2;
  widows: 2;
}

#terms h5 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  text-align: left;
  color: #000;
  font-family: 'Times New Roman';
  font-size: 12pt;
  line-height: 1;

  orphans: 2;
  widows: 2;
}

#terms h6 {
  padding-top: 8pt;
  padding-bottom: 4pt;
  text-align: left;
  color: #000;
  font-family: 'Arial';
  font-size: 10pt;
  font-weight: 700;
  line-height: 1;

  orphans: 2;
  page-break-after: avoid;
  widows: 2;
}

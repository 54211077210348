.btn-link {
  &:hover,
  &:focus,
  &.focus {
    text-decoration: none;
  }
}

.btn:hover {
  border-color: transparent;
}

.btn-secondary {
  color: #fff;
}

.btn-info {
  border-color: #27afd0;
}

.btn-sm i {
  font-size: 20px;
  vertical-align: bottom;
}

.btn-light {
  color: $blue;
  border: 1px solid $blue;

  &:hover {
    color: $blue;
  }
}

.btn-full {
  width: 100%;
  background-color: #feffff;
  border: none;
  color: #053a7a;
  font-size: 16px;
  margin-top: 1rem;
  text-align: left;
  box-shadow: 0px 1px 4px #c5c4c4;

  &:hover,
  &:active,
  &:focus {
    background-color: #e2e8f0 !important;
    color: #053a7a !important;
    border: none !important;
    box-shadow: 0px 1px 4px #c5c4c4 !important;
  }

  &:hover {
    background-color: #e2e8f0 !important;
  }
}

.download i {
  position: absolute;
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none !important;
}

.invoice:hover {
  text-decoration: none;

  span {
    text-decoration: underline;
  }
}
